import React, { useCallback } from 'react'

import { Form } from 'react-bootstrap'
import { setIn } from 'formik'

const { Check, Control, Group } = Form

export default function FormCheckbox({
  field = {},
  form: {
    touched = {},
    errors = {},
    setFieldValue = () => {},
    setTouched = () => {},
  } = {},
  groupClassName = '',
  handleChange,
  validateBeforeChange,
  checkboxIndex,
  checked,
  name,
  label,
  globalRateId,
  ...props
}) {
  const isChecked = checked !== undefined ? !!checked : !!field.value
  const onChange = useCallback(() => {
    // Call validation function if provided
    if (validateBeforeChange) {
      if (isChecked) {
        // Allow unchecking without validation
        setFieldValue(field.name, false)
        return
      }
      const isValid = validateBeforeChange(checkboxIndex)
      if (!isValid) return // Stop execution if validation fails
    }
    if (handleChange) {
      handleChange()
    } else {
      setFieldValue(field.name, !field.value)

      const _touched = setIn({ ...touched }, field.name, true)
      setTouched(_touched)
    }
  }, [field, touched, setFieldValue, setTouched, handleChange])

  return (
    <Group className={groupClassName || ''}>
      <Check
        custom
        type='checkbox'
        id={globalRateId ? globalRateId : name || field.name}
        {...field}
        {...props}
        onChange={onChange}
        checked={isChecked}
        label={label}
      />
      <Control.Feedback type='invalid'>{errors[field.name]}</Control.Feedback>
    </Group>
  )
}
