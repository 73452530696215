import * as Yup from 'yup'
import {
  booleanValidator,
  requiredNumberValidator,
  requiredObject,
  requiredStringValidator,
} from '../form-validation'
import { defaultCurrenciesFour, defaultDistanceUnits } from '../constants'
import _ from 'lodash'
import { logger } from '../actions'
let defaultCurrencyValue = defaultCurrenciesFour[2].label
const defaultMileageLabel = defaultDistanceUnits[0].label

const distanceUnitValueKeys = ['radiusDistanceUnit', 'dailyMileageUnit']
const currencyUnitValueKeys = [
  'currency',
  'depositCurrency',
  'excessCurrency',
  'extraMileCurrency',
]

const valueToLabel = (item, units = [], reverse = false) => {
  const inputKey = reverse ? 'label' : 'value'
  const outputKey = reverse ? 'value' : 'label'
  return (units.find(unit => item === unit[inputKey]) || units[0])[outputKey]
}

const itemValuesToLabels = (item, reverse = false) => {
  const _item = {
    ...item,
  }
  Object.keys(_item).forEach(key => {
    if (distanceUnitValueKeys.includes(key)) {
      _item[key] = valueToLabel(_item[key], defaultDistanceUnits, reverse)
    }
    if (currencyUnitValueKeys.includes(key)) {
      _item[key] = valueToLabel(_item[key], defaultCurrenciesFour, reverse)
    }
    if (Array.isArray(_item[key])) {
      _item[key] = _item[key].map(arrayItem =>
        itemValuesToLabels(arrayItem, reverse)
      )
    }
  })
  return _item
}

export const globalRateLocationFormData = ({ ...values }) => {
  try {
    const data = {
      place: {
        ...values,
      },
      active: true,
    }
    return data
  } catch (error) {
    logger({ fileName: 'globalRateLocations', error: error })
    console.log(error)
    return {}
  }
}

export const globalRateLocationRatesFormDataClone = values => {
  try {
    return values
  } catch (error) {
    logger({ fileName: 'globalRateLocations', error: error })
    console.log(error)
    return {}
  }
}

export const globalRateLocationRatesFormDataDefault = values => {
  try {
    return itemValuesToLabels(values, true)
  } catch (error) {
    logger({ fileName: 'globalRateLocations', error: error })
    console.log(error)
    return {}
  }
}

const formDataChauffeuredAtoB = ({ items, ...props }, reverse = false) => {
  const data = {}
  const inputKey = !reverse ? 'label' : 'value'
  const outputKey = !reverse ? 'value' : 'label'
  data.items = items.map(
    ({
      to = {},
      from = {},
      rate,
      currency,
      isBothWay,
      ignoreAnomaly,
      isRational,
      id,
      distance,
      distanceText,
      duration,
      durationText,
      updatedBy,
      createdBy,
      buyingRate,
      rateType,
      active,
    }) => ({
      id,
      isBothWay,
      ignoreAnomaly,
      isRational,
      rate,
      distance,
      duration,
      distanceText,
      durationText,
      createdBy: createdBy ? createdBy : null,
      updatedBy: updatedBy && updatedBy.length ? updatedBy.pop() : null,
      currency:
        (defaultCurrenciesFour.find(
          formatValue => currency === formatValue[inputKey]
        ) || {})[outputKey] || currency,
      to: {
        place: to.place,
        radius: to.radius,
        radiusDistanceUnit:
          (defaultDistanceUnits.find(
            formatValue =>
              to.radiusDistanceUnit &&
              to.radiusDistanceUnit === formatValue[inputKey]
          ) || {})[outputKey] || to.radiusDistanceUnit,
      },
      from: {
        place: from.place,
        radius: from.radius,
        radiusDistanceUnit:
          (defaultDistanceUnits.find(
            formatValue =>
              from.radiusDistanceUnit &&
              from.radiusDistanceUnit === formatValue[inputKey]
          ) || {})[outputKey] || to.radiusDistanceUnit,
      },
      buyingRate,
      rateType,
      active,
    })
  )
  data.dispatchPercentage = props.dispatchPercentage || 0
  return data
}

const formDataChauffeuredHourly = ({ items, minHours }, reverse = false) => {
  const data = {}

  const inputKey = !reverse ? 'label' : 'value'
  const outputKey = !reverse ? 'value' : 'label'

  data.items = items.map(({ updatedBy, createdBy, currency, ...rest }) => ({
    createdBy: createdBy ? createdBy : null,
    updatedBy: updatedBy && updatedBy.length ? updatedBy.pop() : null,
    currency:
      (defaultCurrenciesFour.find(
        formatValue => currency === formatValue[inputKey]
      ) || {})[outputKey] || currency,
    ...rest,
  }))

  data.minHours = minHours
  return data
}

const formDataChauffeuredDaily = (
  { items, minHoursPerDay },
  reverse = false
) => {
  const data = {}

  const inputKey = !reverse ? 'label' : 'value'
  const outputKey = !reverse ? 'value' : 'label'

  data.items = items.map(({ updatedBy, createdBy, currency, ...rest }) => ({
    createdBy: createdBy ? createdBy : null,
    updatedBy: updatedBy && updatedBy.length ? updatedBy.pop() : null,
    ...rest,
    currency:
      (defaultCurrenciesFour.find(
        formatValue => currency === formatValue[inputKey]
      ) || {})[outputKey] || currency,
  }))
  data.minHoursPerDay = minHoursPerDay
  return data
}

const formDataSelfDrive = (info, reverse = false) => {
  const data = {}
  const items = info.items
  const inputKey = !reverse ? 'label' : 'value'
  const outputKey = !reverse ? 'value' : 'label'

  data.items = items.map(({ updatedBy, createdBy, currency, ...rest }) => ({
    createdBy: createdBy ? createdBy : null,
    updatedBy: updatedBy && updatedBy.length ? updatedBy.pop() : null,
    ...rest,
    currency:
      (defaultCurrenciesFour.find(
        formatValue => currency === formatValue[inputKey]
      ) || {})[outputKey] || currency,
  }))

  data.extraMileCurrency =
    (defaultCurrenciesFour.find(
      formatValue => info.extraMileCurrency === formatValue[inputKey]
    ) || {})[outputKey] || info.extraMileCurrency

  data.depositCurrency =
    (defaultCurrenciesFour.find(
      formatValue => info.depositCurrency === formatValue[inputKey]
    ) || {})[outputKey] || info.depositCurrency
  data.excessCurrency =
    (defaultCurrenciesFour.find(
      formatValue => info.excessCurrency === formatValue[inputKey]
    ) || {})[outputKey] || info.excessCurrency

  data.dailyMileageUnit =
    reverse && info.dailyMileageUnit
      ? info.dailyMileageUnit.charAt(0).toUpperCase() +
        info.dailyMileageUnit.slice(1)
      : 'miles'

  data.dailyMileage = info.dailyMileage
  data.deposit = info.deposit
  data.excess = info.excess
  data.extraMile = info.extraMile
  data.minDays = info.minDays

  return data
}
export const initialChauffeuredAtoBRatePlace = Yup.object().shape({
  radius: requiredStringValidator(),
  radiusDistanceUnit: requiredStringValidator({
    defaultValue: defaultMileageLabel,
  }),
  place: requiredObject({
    defaultValue: null,
  }),
})

export const initialChauffeuredAtoBRate = Yup.object().shape({
  from: initialChauffeuredAtoBRatePlace,
  to: initialChauffeuredAtoBRatePlace,
  rate: requiredStringValidator(),
  currency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
  buyingRate: Yup.number().optional(),
  isBothWay: booleanValidator(),
})

export const initialChauffeuredAtoBRateGrid = Yup.object().shape({
  from: initialChauffeuredAtoBRatePlace,
  to: initialChauffeuredAtoBRatePlace,
  rate: requiredStringValidator(),
  currency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
  buyingRate: Yup.number().optional(),
  isBothWay: booleanValidator(),
  ignoreAnomaly: booleanValidator(),
  isRational: booleanValidator(),
})

export const initialChauffeuredAtoB = Yup.object().shape({
  dispatchPercentage: Yup.number().optional(),
  items: Yup.array()
    .of(initialChauffeuredAtoBRate)
    .default([initialChauffeuredAtoBRate.cast()]),
})

export const initialSmartRates = Yup.object().shape({
  farebase: requiredNumberValidator(),
  DistanceWeight: requiredNumberValidator(),
  DurationWeight: requiredNumberValidator(),
  currency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
  costMile: requiredNumberValidator(),
  costMinute: requiredNumberValidator(),
})
export const initialGlobalSmartRates = Yup.object().shape({
  items: Yup.array()
    .of(initialSmartRates)
    .default([initialSmartRates.cast()]),
})

export const initialChauffeuredHourlyRate = Yup.object().shape({
  hours: requiredStringValidator({ defaultValue: 1 }),
  rate: requiredStringValidator(),
  buyingRate: Yup.number().optional(),
  currency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
})

export const initialChauffeuredHourly = Yup.object().shape({
  minHours: requiredStringValidator(),
  items: Yup.array()
    .of(initialChauffeuredHourlyRate)
    .default([initialChauffeuredHourlyRate.cast()]),
})

export const initialChauffeuredDailyRate = Yup.object().shape({
  days: requiredStringValidator(),
  rate: requiredStringValidator(),
  currency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
})

export const initialChauffeuredDaily = Yup.object().shape({
  minHoursPerDay: requiredStringValidator(),
  items: Yup.array()
    .of(initialChauffeuredDailyRate)
    .default([initialChauffeuredDailyRate.cast()]),
})

export const initialSelfDriveDailyRate = Yup.object().shape({
  days: requiredStringValidator(),
  rate: requiredStringValidator(),
  currency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
})

export const initialSelfDriveDaily = Yup.object().shape({
  minDays: requiredStringValidator(),
  deposit: requiredStringValidator(),
  depositCurrency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
  excess: requiredStringValidator(),
  excessCurrency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
  dailyMileage: requiredStringValidator(),
  dailyMileageUnit: requiredStringValidator({
    defaultValue: defaultMileageLabel,
  }),
  extraMile: requiredStringValidator(),
  extraMileCurrency: requiredStringValidator({
    defaultValue: defaultCurrencyValue,
  }),
  items: Yup.array()
    .of(initialSelfDriveDailyRate)
    .default([initialSelfDriveDailyRate.cast()]),
})

export const inititalLocationRates = () => ({
  chauffeured: {
    aToB: {
      schema: initialChauffeuredAtoB,
      formDataFormatter: formDataChauffeuredAtoB,
    },
    smartRates: {
      schema: initialGlobalSmartRates,
    },
    hourly: {
      schema: initialChauffeuredHourly,
      formDataFormatter: formDataChauffeuredHourly,
    },
    daily: {
      schema: initialChauffeuredDaily,
      formDataFormatter: formDataChauffeuredDaily,
    },
  },
  self_drive: {
    daily: {
      schema: initialSelfDriveDaily,
      formDataFormatter: formDataSelfDrive,
    },
  },
})

export class GlobalRateLocationRateTypeInitialValues {
  constructor({ mode, rateType, currency, continent, ...data } = {}) {
    const { schema, formDataFormatter } = inititalLocationRates()[mode][
      rateType
    ]
    const defaultModeRates = schema.cast()
    let formattedData = {}
    const dataItems = {
      ...data,
    }
    let fetchedTypeRates = {
      ...data,
    }

    fetchedTypeRates = formDataFormatter
      ? formDataFormatter(fetchedTypeRates, true)
      : itemValuesToLabels(fetchedTypeRates)

    formattedData = {
      ...defaultModeRates,
      ...fetchedTypeRates,
      items:
        fetchedTypeRates.items && fetchedTypeRates.items.length
          ? fetchedTypeRates.items.map(
              ({ id, locationId, globalRateId, ...rest }) => {
                return {
                  id,
                  ...rest,
                }
              }
            )
          : defaultModeRates.items,
    }
    if (mode === 'self_drive') {
      formattedData.extraMileCurrency = currency
      formattedData.excessCurrency = currency
      formattedData.depositCurrency = currency
      if (continent && continent.unitSystem === 'KM') {
        formattedData.dailyMileageUnit = 'Km'
        formattedData.dailyMileage = Math.round(
          formattedData.dailyMileage / 0.62137
        )
        formattedData.extraMile = (formattedData.extraMile / 1.60934).toFixed(2)
        formattedData.convertedValues = true
      }
    }

    if (rateType !== 'smartRates' && dataItems.items.length === 0) {
      _.forEach(formattedData.items, item => {
        if (item.rate === '') {
          item.currency = currency
        }
      })
    }

    this.data = formattedData
  }
}

export class CardsInitialValues {
  constructor({ title, clients, locationSearch, ...props } = {}) {
    this.title = title
    this.locationSearch = locationSearch
    if (clients) {
      this.Clients = clients.map(client => ({
        label: client.name,
        value: client.clientId,
        id: client.clientId,
      }))
    }
  }
}
